import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowNavigation = _resolveComponent("ArrowNavigation")!

  return (_openBlock(), _createBlock(_component_ArrowNavigation, {
    textLeft: _ctx.$t('navigation.next_sura'),
    textRight: _ctx.$t('navigation.previous_sura'),
    leftLink: _ctx.nextSura,
    rightLink: _ctx.previousSura,
    textMiddle: _ctx.overviewText,
    middleLink: _ctx.middleLink
  }, null, 8, ["textLeft", "textRight", "leftLink", "rightLink", "textMiddle", "middleLink"]))
}