
import { defineComponent, onMounted, onUpdated, PropType, ref } from 'vue';
import { connectQuranRefsToPopper } from '@/components/commentary/popper';
import { createLinks } from '@/components/commentary/linker';
import { Commentary } from '@/interfaces/Commentary';
import CommentarySection from '@/components/commentary/CommentarySection.vue';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import CommentaryLine from '@/components/commentary/CommentaryLine.vue';
import { useRoute, useRouter } from 'vue-router';
import routeParamToString from '@/router/param_as_string';
import scrollToHash from '@/components/global/scrollToHash';
import { useI18n } from 'vue-i18n';
import MoreInfo from '@/components/global/MoreInfo.vue';
import BackToTop from '../intertext/BackToTop.vue';

export default defineComponent({
  components: {
    BackToTop,
    CommentarySection,
    ArticleContainer,
    CommentaryLine,
    MoreInfo,
  },
  props: {
    commentary: Object as PropType<Commentary>,
  },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });

    console.log('Übersetzungswert:', t('commentary.text_infotext')); // Überprüft, ob der Schlüssel existiert
    const showInfo = ref(false);
    /** Note: this setup logic is largely a duplicate
    of the CommentaryIntroduction logic. Maybe you can combine them?
    * */
    const router = useRouter();
    const route = useRoute();

    function makeLinksAndPoppers() {
      createLinks(router, route);

      // Hook up Quran references to popper.
      connectQuranRefsToPopper(routeParamToString(route.params.lang));
    }

    onMounted(() => {
      scrollToHash(route);

      makeLinksAndPoppers();
    });

    onUpdated(() => {
      makeLinksAndPoppers();
    });
    return {
      t,
      showInfo,
    };
  },
});
