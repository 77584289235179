import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_SuraNavigation = _resolveComponent("SuraNavigation")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!
  const _component_TableOfContents = _resolveComponent("TableOfContents")!
  const _component_CommentaryArticle = _resolveComponent("CommentaryArticle")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!
  const _component_NotFound = _resolveComponent("NotFound")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      infoText: _ctx.commentaryWeb.payload?.infotext
    }, {
      title: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.$t('navigation.commentary') + ' <small>' + _ctx.$t('global.sura') + ' '
+ _ctx.$route.params.sura + '</small>'
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["infoText"]),
    _createVNode(_component_SuraNavigation, {
      overviewText: _ctx.$t('commentary.to_overview'),
      middleLink: { name: 'CommentaryOverview' }
    }, null, 8, ["overviewText"]),
    _createVNode(_component_Popper),
    (_ctx.isLoaded(_ctx.commentary))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isLoaded(_ctx.commentary))
            ? (_openBlock(), _createBlock(_component_ArticleContainer, {
                key: 0,
                class: "my-3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", {
                    innerHTML: _ctx.commentary.payload.title,
                    class: "py-3"
                  }, null, 8, _hoisted_2),
                  _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.commentary.payload.author), 1),
                  (_ctx.commentaryEra(+_ctx.$route.params.sura))
                    ? (_openBlock(), _createBlock(_component_i18n_t, {
                        key: 0,
                        keypath: "commentary.classification",
                        tag: "p",
                        class: "showLinks"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: {
            name: 'CommentaryIntroduction',
            params: {
              intro: _ctx.commentaryEra(+_ctx.$route.params.sura),
            },
          }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(`commentary.intro_${_ctx.commentaryEra(+_ctx.$route.params.sura)}`)), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isLoaded(_ctx.commentary))
            ? (_openBlock(), _createBlock(_component_TableOfContents, {
                key: 1,
                listing: _ctx.toTableOfContents(_ctx.commentary.payload),
                class: "my-6"
              }, null, 8, ["listing"]))
            : _createCommentVNode("", true),
          (_ctx.isLoaded(_ctx.commentary))
            ? (_openBlock(), _createBlock(_component_CommentaryArticle, {
                key: 2,
                commentary: _ctx.commentary.payload
              }, null, 8, ["commentary"]))
            : _createCommentVNode("", true),
          _createVNode(_component_HowToQuote, {
            citation: _ctx.commentary.payload.how_to_cite
          }, null, 8, ["citation"])
        ], 64))
      : (_ctx.notFound(_ctx.commentary))
        ? (_openBlock(), _createBlock(_component_NotFound, {
            key: 1,
            class: "pt-12"
          }))
        : _createCommentVNode("", true)
  ], 64))
}